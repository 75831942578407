<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card color="blue lighten-5">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    @change="(event) => updateSales(event)"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"

                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saleses"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled_sales"

                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="Customer" 
                                    :items="datas" 
                                    item-value="namacustomer" 
                                    item-text="namacustomer"
                                    label="Customer Name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-dialog
                                    ref="dialog_from"
                                    v-model="modal"
                                    :return-value.sync="date_from"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field
                                        solo
                                        clearable
                                        v-model="date_from"
                                        label="Date from"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attr"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date_from"
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog_from.save(date_from)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-dialog
                                    ref="dialog"
                                    v-model="modal_to"
                                    :return-value.sync="date_to"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo
                                        clearable
                                        v-model="date_to"
                                        label="Date to"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date_to"
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal_to = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(date_to)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="search()">Search</v-btn>
                            </v-col>
                            <v-row class="mt-2" style="padding-bottom:50px">        
                            <v-col cols="12">
                                <v-card>
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title>Result
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                    small
                                                    color="#005c37"
                                                    class="py-5 mr-3 text-white"
                                                    >
                                                        <v-icon>mdi-file-excel</v-icon>
                                                        <download-excel
                                                            class="text"
                                                            :fetch           = "exportExcel"
                                                            :fields="headersColumn"
                                                            :before-generate = "startDownload"
                                                            :before-finish   = "finishDownload">
                                                            Export Excel
                                                        </download-excel>
                                                    </v-btn>
                                                </v-card-title>
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="purchases"
                                                    :loading="loading"
                                                    page-count="5"
                                                    loading-text="Please wait, retrieving data"
                                                    class="elevation-1"
                                                >
                                                    <template v-slot:[`item.activity`]="{ item }">
                                                        <div v-html="item.activity">

                                                        </div>
                                                    </template>
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                                        mdi-google-maps
                                                                    </v-icon>
                                                                </template>
                                                                <span>View Map</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td v-if="item.customerid != 'NEW'">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="showFileDetail(item)" v-on="on">
                                                                        mdi-file-image
                                                                    </v-icon>
                                                                </template>
                                                                <span>View Image</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="printItem(item)" v-on="on">
                                                                        mdi-printer
                                                                    </v-icon>
                                                                </template>
                                                                <span>Print</span>
                                                            </v-tooltip>
                                                        </td>
                                                         <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="showdetails(item)" v-on="on">
                                                                        mdi-eye
                                                                    </v-icon>
                                                                </template>
                                                                <span>Details</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                                <v-dialog v-model="dialog" max-width="1000px">                
                                    <v-card>
                                        <v-card-title>Detail Location</v-card-title>
                                        <v-card-text>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                                            <tbody>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width: 80%;">
                                                                                        <div class="d-flex">
                                                                                            <img src="@/assets/kbt.png" height="60px" alt="">
                                                                                            <p class="mt-4 ml-4">KBT</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>Sales Activity</strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                                            <tbody>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Date
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.tr_date : '' }}
                                                                                    </td>
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Activity
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td  style="width:30%">
                                                                                        <div v-html="this.po_item ? this.po_item.activity : ''" style="color: black;"></div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Customer
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.namacustomer : '' }}
                                                                                    </td>
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Sales ID
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.salesid : '' }} - {{ this.po_item ? this.po_item.nama : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Address
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4"  style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.alamat : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Remark
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4" style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.remark : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Image
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4"  style="width:30%">
                                                                                        <v-img
                                                                                        max-height="150"
                                                                                        max-width="250"
                                                                                        :src="this.po_item ? this.po_item.img_url : ''"
                                                                                        ></v-img>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div id="map_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog
                                    v-model="dialogdetails"
                                    max-width="1300px"
                                    >
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <h4
                                                class="text-center"
                                                style="
                                                font-weight: 600;
                                                color: #053d76;
                                                margin-bottom: 15px;
                                                margin-top: 2px;">
                                                Details Kunjungan
                                                </h4>
                                            <v-divider
                                                style="
                                                border-top: 3px solid #0078d4;
                                                margin-top: 5px;"></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-data-table
                                        fixed-header
                                        height="350"
                                        :headers="headersdetail"
                                        :items="details"
                                        :loading="loading"
                                        loading-text="Please wait, retrieving data"
                                        page-count="10"
                                        class="elevation-1"
                                        >
                                        <template v-slot:[`item.jumlah`]="{ item }">
                                            <v-chip class="ma-2" color="success"> 
                                                {{item.jumlah}} 
                                                </v-chip> 
                                            </template>
                                        </v-data-table> 
                                            <v-card-actions class="text-center mt-10">
                                                <v-row justify="center">
                                                    <v-col cols="12" xs="12" sm="3" md="2">
                                                        <v-btn block class="rounded-l text-white"  color="red" @click.stop="dialogdetails=false">Close</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                        </v-card>
                                </v-dialog>
                                <v-dialog
                                    v-model="dialogPrint"
                                    persistent
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    style="border-radius: 0 !important"
                                >
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        Print Sales Activity
                                                        <v-spacer></v-spacer>
                                                        <v-btn class="rounded-l text-white" color="success" @click="printTicket()">
                                                            <v-icon small class="mr-2">
                                                                mdi-printer
                                                            </v-icon>
                                                            Print
                                                        </v-btn>
                                                        <v-btn
                                                            class="mx-2"
                                                            fab
                                                            x-small
                                                            color="#e83e8c;"
                                                            @click="[dialogPrint = false]"
                                                        >
                                                            <v-icon dark>
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                                <v-divider></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row align="center" justify="center" class="mt-4">
                                                <div style="width: 960px;" id="divPrintSalesActivityKbt">
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                                                    <tbody>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width: 80%;">
                                                                                                <div class="d-flex">
                                                                                                    <img src="@/assets/kbt.png" height="60px" alt="">
                                                                                                    <p class="mt-4 ml-4">KBT</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <strong>Sales Activity</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                                                    <tbody>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Date
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.tr_date : '' }}
                                                                                            </td>
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Activity
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td  style="width:30%">
                                                                                                <div v-html="this.po_item ? this.po_item.activity : ''" style="color: black;"></div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Customer
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.namacustomer : '' }}
                                                                                            </td>
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Sales ID
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.salesid : '' }} - {{ this.po_item ? this.po_item.nama : '' }}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Address
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td colspan="4"  style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.alamat : '' }}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Remark
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td colspan="4" style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.remark : '' }}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="mt-5">
                                                            <v-col cols="12">
                                                                <div id="map_print_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </div>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-dialog
                                    v-model="dialog_file_doc"
                                    persistent
                                    max-width="1300px"
                                    >
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <h4
                                                class="text-center"
                                                style="
                                                font-weight: 600;
                                                color: #053d76;
                                                margin-bottom: 15px;
                                                margin-top: 2px;">
                                                Report Sales
                                                </h4>
                                            <v-divider
                                                style="
                                                border-top: 3px solid #0078d4;
                                                margin-top: 5px;"></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                                                <v-row>
                                                    <v-col class="col-12" sm="4" md="4">
                                                        <v-card class="elevation-1 rounded-5">
                                                            <v-card-text class="mt-15">
                                                                <!-- <td v-if="item.img_url != ''"> -->
                                                                <v-img :src=imageUrl ></v-img>
                                                                <!-- </td> -->
                                                                <!-- <v-img v-for="file in doc_files" :key="file.file_name" :src="file.file_name"></v-img> -->
                                                                <p class="css-bqy72x mt-5">Besar file: maksimum (1 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col class="col-12" sm="8" md="8">
                                                    <v-container fluid>
                                                        <v-col cols="12">
                                                            <h5 class="text-center" 
                                                            style="
                                                            font-weight: 600;
                                                            color: #053d76;
                                                            margin-bottom: 45px;
                                                            margin-top: 2px;"><span>Report Sales Info</span></h5>
                                                        </v-col>
                                                        <v-row>
                                                        <v-col class="col-12" sm="3" md="4">
                                                            <h6 class="blue-lcd mb-1">Sales ID<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable
                                                                v-model="salesid" 
                                                                disabled 
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <v-col class="col-12" sm="3" md="4">
                                                            <h6 class="blue-lcd mb-1">Sales Name<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable 
                                                                v-model="salesname"
                                                                disabled
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <v-col class="col-12" sm="3" md="4">
                                                            <h6 class="blue-lcd mb-1">Customer Name<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable
                                                                v-model="namacustomer"
                                                                disabled 
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <v-col class="col-12" sm="3" md="4">
                                                            <h6 class="blue-lcd mb-1">Address<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable
                                                                v-model="alamat"
                                                                disabled
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <v-col class="col-12" sm="3" md="4">
                                                            <h6 class="blue-lcd mb-1">City<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable
                                                                v-model="city"
                                                                disabled
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <v-col class="col-12" sm="3" md="4">
                                                            <h6 class="blue-lcd mb-1">Date<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable 
                                                                v-model="tr_date"
                                                                disabled
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <v-col class="col-12" sm="3" md="10">
                                                            <h6 class="blue-lcd mb-1">Lt Location<span class="red--text"></span></h6>
                                                            <v-text-field
                                                                dense
                                                                solo
                                                                clearable 
                                                                v-model="lt_location"
                                                                disabled
                                                            ></v-text-field> 
                                                        </v-col>
                                                        <div class="col-lg-12 ">
                                                            <h6 class="blue-lcd mb-1">Remark<span class="red--text"></span></h6>
                                                            <v-textarea v-model="remark" label="" disabled clearable outlined></v-textarea>
                                                        </div>
                                                        <v-row class="mt-12">
                                                                        <v-col cols="12">
                                                                            <v-alert
                                                                            icon="mdi-shield-lock-outline"
                                                                            prominent
                                                                            text
                                                                            color="red"
                                                                            >
                                                                            Cek kembali data setiap customer pastikan data benar dan tidak ada kesalahan input data customer
                                                                            </v-alert>
                                                                        </v-col>
                                                                    </v-row>
                                                            </v-row>
                                                    </v-container>
                                                    </v-col>
                                                    </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                    <v-card-actions class="text-center">
                                                        <v-row justify="center">
                                                            <v-col cols="12" xs="12" sm="3" md="2">
                                                                <v-btn block class="rounded-l text-white"  color="red" @click.stop="dialog_file_doc=false">Close</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-actions>
                                                </v-card>
                                </v-dialog>
                                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                                    <v-layout align-center pr-4>
                                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                                        <v-layout column>
                                            <div>
                                            <strong>{{ snackbar.title }}</strong>
                                            </div>
                                            <div>{{ snackbar.text }}</div>
                                        </v-layout>
                                    </v-layout>
                                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                                        <v-icon>clear</v-icon>
                                    </v-btn>
                                </v-snackbar>
                            </v-col>
                        </v-row>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
      </v-row>

        <div class="modal fade modal-md" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <v-img :src=imageUrl ></v-img>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-xl" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                    <div class="modal-header">
                        <h4 class="modal-title">Sales Visit Feedback</h4>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12">
                            <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback1" ></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback2"></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <v-btn class="" color="success" elevation="2" block rounded @click="storeFeedback()" >SUBMIT</v-btn>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/kbt',
                },
                {
                text: 'Sales Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Activity Visit Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            saleses: [],
            sales: '',
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                { text: 'Salesman', align: 'start',
                    sortable: true, value: 'nama' },
                { text: 'Customer', value: 'namacustomer' },
                { text: 'Id Cust', value: 'customerid' },
                { text: 'Address', value: 'alamat' },
                { text: 'City', value: 'city' },
                { text: 'Date', value: 'tr_date' },
                { text: 'Activity', value: 'activity' },
                { text: 'Remark', value: 'remark' },
                { text: 'Actions',  value: 'actions', width:'100', sortable: false },
            ],
            headersColumn: {
                'Trans Date': 'tr_date',
                'Customer ID': 'customerid',
                'Customer': 'namacustomer',
                'Sales': 'nama',
                'Sales ID': 'salesid',
                'Address': 'alamat',
                'City': 'city',
                'Activity': 'activities',
                'Remark': 'remark',
            },
            details: [],
            headersdetail: [
                { text: 'Sales ID', align: 'start',
                sortable: true, value: 'salesid' },
                { text: 'Customer ID', value: 'customerid' },
                { text: 'Nama Customer', value: 'namacustomer' },
                { text: 'Date', value: 'tr_date' },
                { text: 'Kujungan', value: 'jumlah' },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            po_items: [],
            datas:[],
            Customer:'',
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            dialog_file_doc:false,
            totalItem_po_items: 10,
            dialog: false,
            user_approve1: '',
            dialogdetails:false,
            namacustomer:'',
            alamat:'',
            tr_date:'',
            salesname:'',
            remark:'',
            city:'',
            lt_location:'',
            salesid:'',
            user_approve2: '',
            pagination: 1,
            mymap: '',
            data_var: {
                entity_id : 'KBT',
                appl_id : 'WEBKBT'
            },
            group: '',
            disabled: false,
            disabled_sales: false,
            dialogPrint: false,
            imageUrl: '',
            kacabFeedbackModel: '',
            disabled_feedback1: false,
            salesFeedbackModel: '',
            disabled_feedback2: false
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getOffice()
        await this.getEnvConf()
        this.GetCustomer()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'OFFICEID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.office = res.data.data[i]['var_value']
                            // this.getOffice()
                            this.disabled = true

                            this.updateSales(this.office)
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'KOORDINATOR_SALES') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.group = 'KOORDINATOR_SALES'
                        }
                    }
                    
                    if (this.group === 'KOORDINATOR_SALES') {
                        if (res.data.data[i]['var_id'] === 'SALESID' && this.group === 'KOORDINATOR_SALES') {
                            if (res.data.data[i]['var_value'] != null) {
                                // this.updateSales(this.office)
                                this.sales = res.data.data[i]['var_value']
                            }
                        } else{
                            if (res.data.data[i]['var_id'] === 'SALESID') {
                                if (res.data.data[i]['var_value'] != null) {
                                    // this.updateSales(this.office)
                                    this.sales = res.data.data[i]['var_value']
                                    this.disabled_sales = true
                                }
                            }
                        }
                    } else{
                        if (res.data.data[i]['var_id'] === 'SALESID') {
                            if (res.data.data[i]['var_value'] != null) {
                                // this.updateSales(this.office)
                                this.sales = res.data.data[i]['var_value']
                                this.disabled_sales = true
                            }
                        }
                    }

                    
                }

                // this.getPullData(1, 10)

            })
        },
        async showdetails(item){
            console.log(item)
            this.namacustomer = item.namacustomer
            this.dialogdetails = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/sales_activity/Detail?search=${this.$store.state.text}&namacustomer=${this.namacustomer ? this.namacustomer : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data)
                this.details = res.data
            })
            
        },
        async GetCustomer(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/sales_activity/GetCustomer`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.datas = res.data
            })
            
        },
        search(){
            this.pagination = 1
            this.getPullData(1, 10, true)
        },
        close() {
            this.dialog = false
        },
        async printItem(item){
            this.po_item = item
            this.dialogPrint = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_print_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KBT}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async showItem(item){
            this.po_item = item
            this.dialog = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KBT}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/sales_activity?search=${this.$store.state.text}&txtOffID=${this.office ? this.office : ""}&salesid=${this.sales ? this.sales : ""}&namacustomer=${this.Customer ? this.Customer : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                // this.totalItems = res.data.meta.total
                // this.pagination = res.data.meta.current_page

            })
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data.office
                this.saleses = res.data.sales
            });
        },
        async updateSales(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang?officeid=${value ? value : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saleses = res.data.sales
            });
        },
        printTicket(){
            const elem = document.getElementById("divPrintSalesActivityKbt")
            var domClone = elem.cloneNode(true);
    
            var $printSectionSalesActivityKbt = document.getElementById("printSectionSalesActivityKbt");
            
            if (!$printSectionSalesActivityKbt) {
                var $printSectionSalesActivityKbt = document.createElement("div");
                $printSectionSalesActivityKbt.id = "printSectionSalesActivityKbt";
                document.body.appendChild($printSectionSalesActivityKbt);
            }
            
            $printSectionSalesActivityKbt.innerHTML = "";
            $printSectionSalesActivityKbt.appendChild(domClone);
            window.print();
        },  
        async exportExcel(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            var respData = await axios.get(`${process.env.VUE_APP_URL}/api/kbt/sales_activity/export?search=${this.$store.state.text}&txtOffID=${this.office ? this.office : ""}&salesid=${this.sales ? this.sales : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })

            if (respData.status == 200) {
                return respData.data.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        showFileDetail(item){
          console.log(item);
          this.namacustomer = item.namacustomer
          this.salesid = item.salesid
          this.remark = item.remark
          this.alamat = item.alamat
          this.city = item.city
          this.salesname = item.nama
          this.tr_date = item.tr_date
          this.lt_location = item.lt_location
          this.item = item.img_url
          this.dialog_file_doc = true
          this.showImageModal(item.img_url)
        //this.getIndex()
        },
        showImageModal(imgUrl){

            console.log(imgUrl)
            this.dialog_file_doc = true
            // $('#imgModal').modal('show')
            this.imageUrl = process.env.VUE_APP_URL + imgUrl

        },
        showFeedback(item){

            console.log(item.tr_id);

            // $('#feedbackModal').modal('show')

        },
        storeFeedback(){

        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}

fieldset.document {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}
.v-money { text-align: right !important; }
.tr_choice{
  background-color: #a5d8ed !important;
}
@media screen {
  #printSectionSalesActivityKbt {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSectionSalesActivityKbt, #printSectionSalesActivityKbt * {
    visibility:visible;
  }
  #printSectionSalesActivityKbt {
    position:absolute;
    left:0;
    top:0;
  }
}

</style>